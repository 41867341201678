import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import "twin.macro"

import { Article, BaseLayer, Container, Headings, Section, Typography, Wrapper } from "@/atoms"
import {
  BreadCrumbs,
  Layout,
  NextSEO,
  SEO,
  TOC
} from "@/components"
import { BlogPostByIdQuery, SitePageContext } from "@/gatsby-graphql"
import { extractParagraph, formatDate, tocfy } from "@/utils"
import tw from "twin.macro"

export type BlogPostPageProps = {
  data: BlogPostByIdQuery
  pageContext: SitePageContext
  location: any
}

const BlogPostTemplate = ({ data, pageContext, location }: BlogPostPageProps) => {
  const post = data.wpPost
  const image = getImage(post?.featuredImage?.node?.localFile)

  const { toc, html } = tocfy(post.content)

  return (
    <Layout>
      {/* <SEO
        title={post.title}
        description={
          extractParagraph(post.excerpt, 150) || extractParagraph(html, 150)
        }
        metaImage={image}
        keywords={post.tags.nodes.map(node => node.name)}
      /> */}
      <NextSEO
        type="BlogPost"
        title={post.title}
        description={
          extractParagraph(post.excerpt, 100) || extractParagraph(html, 100)
        }
        url={`/blog/${post.slug}`}
        datePublished={post.date}
        dateModified={post.modified}
        breadCrumbs={[
          { title: "ブログ", uri: "/blog" },
          {
            title: post.categories.nodes[0].name,
            uri: post.categories.nodes[0].uri,
          },
          { title: post.title },
        ]}
      />
      <Article css={{
        ...tw`space-y-12`,
      }}>
        <BaseLayer as='header'
          css={{
            ...tw`mt-12`,
            backgroundColor: '$background-tertiary'

          }}>
          <Wrapper
            // center
            maxWidth={'tablet'}
            py='6'
            px='4'
            css={{ ...tw`space-y-2` }}
          >
            <BreadCrumbs
              breadCrumbs={[
                { title: "ブログ", uri: "/blog" },
                {
                  title: post.categories.nodes[0].name,
                  uri: post.categories.nodes[0].uri,
                },
                { title: post.title },
              ]}
            />
            <Headings variant="h1">{post.title}</Headings>
            <div css={{ ...tw`flex flex-col text-sm` }}>
              <span css={{ ...tw`mb-1` }} >{post.categories.nodes[0].name}</span>
              <time >投稿日 {formatDate(post.date)}</time>
              <time >最終更新日 {formatDate(post.modified)}</time>
            </div>
          </Wrapper>
        </BaseLayer>
        {post.featuredImage && (
          <Section>
            <GatsbyImage image={image} alt={post.featuredImage.node.altText} />
          </Section>
        )}
        {/* <div tw="laptop:(w-full flex flex-row-reverse justify-center)"> */}
        <Section>
          <Wrapper
            maxWidth={'tablet'}
            px='4'
          >
            <Typography prose={'base'} dangerouslySetInnerHTML={{ __html: html }} />
            {toc.length > 0 && <TOC toc={toc} />}
          </Wrapper>
        </Section>
      </Article>
    </Layout >
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($databaseId: Int!, $nextId: Int, $prevId: Int) {
    wpPost(databaseId: { eq: $databaseId }) {
      date
      modified
      databaseId
      excerpt
      content
      title
      slug
      author {
        node {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      tags {
        nodes {
          link
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                aspectRatio: 2
                transformOptions: { fit: COVER, cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    nextPost: wpPost(databaseId: { eq: $nextId }) {
      title
      uri
    }
    prevPost: wpPost(databaseId: { eq: $prevId }) {
      title
      uri
    }
  }
`
